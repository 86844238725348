import React from 'react'
import Layout from "../components/layout";
import {Header} from "semantic-ui-react";
import HeaderSubHeader from "semantic-ui-react/dist/commonjs/elements/Header/HeaderSubheader";

const NotFoundPage = () => (
    <Layout>
        <Header textAlign='center'> Page not found </Header>
        <HeaderSubHeader>Check that you followed the correct address</HeaderSubHeader>
    </Layout>
)

export default NotFoundPage
